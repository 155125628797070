import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import SubLayout from '../../components/layouts/SubLayout'
import Seo from '../../components/Seo'
import StyledGeneralPage from '../../components/styles/StyledGeneralPage'

const FeesAndPaymentOptionsPage = () => {
  return (
    <SubLayout>
      <Seo title='Fees & Payment Options' pathname='/enrolment/fees-payment-options' />
      <StyledFeesAndPaymentOptionsPage>
        <h1>Fees & Payment Options</h1>

        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          <Link to='/enrolment/'>Enrolment</Link> {`>`}&nbsp;
          Fees & Payment Options
        </p>

        <p>UniCollege offers excellent value in South African’s private education sector. When every Rand counts, finding value for money has never been more important.</p>

        <h2>Our Value Offering includes</h2>
        <ul>
          <li>Quality academic support alongside CampusOnline, our online student platform – your success is our success!</li>
          <li>A high number of face-to-face contact hours in small classes</li>
          <li>Carefully selected lecturers – both for their industry experience and their ability to communicate well and engage with students when lecturing</li>
          <li>Extra modules that add value that you won’t find elsewhere</li>
          <li>We organise all your textbooks, course material and examinations which are included in the course fees</li>
          <li>Prompt, friendly and efficient customer service – our environment is warm and welcoming</li>
          <li>Our management has an open door policy with a 24 hour turnaround on all complaints</li>
        </ul>

        <h2>Payment options</h2>
        <p>All our fees include your study material, textbooks and examination fees.</p>
        <p>There are 3 ways to pay for your part-time and full-time studies:-</p>
        <ul>
          <li>Pay all your fees upfront or by the end of the first month after you commence your studies to qualify for the cash fee</li>
          <li>Pay a deposit before your studies commence and the balance of the fees over a specified period</li>
          <li>Contact us if you would like to pay a higher or lower deposit and we will work out a payment arrangement for you</li>
        </ul>

        <h2>Steps to register</h2>
        <ul>
          <li>Speak to a Career Counsellor if you have any questions about your chosen course</li>
          <li>Ensure you meet the entry criteria for your qualification of choice</li>
          <li>Confirm duration and tuition fees</li>
          <li>Complete an enrolment form (download <a href='./'>HERE</a>) or register online <Link to='/registration/'>HERE</Link></li>
          <li>Pay the registration fee (non-refundable)</li>
          <li>Once payment has been made, a Student Advisor will contact you to complete the application process</li>
          <li>Pay the cash fee or deposit at least 7 working days before the course start date to ensure you receive your course material on time</li>
        </ul>

        <h2>Fee schedules</h2>
        <p><Link to='/contact/'><strong>Contact us</strong></Link> for our fee schedules. Registration fees are non-refundable.<br />
        UniCollege offers affordable and flexible payment plans. Contact us if you would like to make an alternative payment arrangement.</p>

        <p><strong>Please note UniCollege does not accept NSFAS funding.</strong></p>
      </StyledFeesAndPaymentOptionsPage>
    </SubLayout>
  )
}

const StyledFeesAndPaymentOptionsPage = styled(StyledGeneralPage)`
`

export default FeesAndPaymentOptionsPage
